import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import TransitionLink from 'gatsby-plugin-transition-link';

export default function Menu() {
  return (
    <StaticQuery
      query={graphql`
      query MyQuery {
        allMarkdownRemark (sort: {fields: frontmatter___id, order: ASC}, limit: 8) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
      `}
      render={data => (
        <Navbar 
        collapseOnSelect 
        expand="lg" 
        bg="black" 
        variant="dark"
        fixed="top">
        <AniLink 
        className="nav-link navbar-brand"
        color="limegreen"
        paintDrip 
        to="/">
            <span><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tallymark-3" width="56" height="56" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z"/>
            <line x1="8" y1="5" x2="8" y2="19" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="16" y1="5" x2="16" y2="19" />
          </svg></span>masonmedia
        </AniLink>
        <Navbar.Toggle id="navbar-toggler" aria-controls="responsive-navbar-nav" className="border-0">

          {/* 4 dots */}
          <svg id="mobile-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40"><g fill='#FFFFFF'><circle cx="7" cy="7" r="4"></circle><circle cx="17" cy="7" r="4"></circle><circle cx="7" cy="17" r="4"></circle><circle cx="17" cy="17" r="4"></circle></g></svg>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <AniLink 
            className="nav-link" 
            cover
            bg="#000" 
            entryoffset={80}
            direction="down" 
            duration={1}
            to="/">
              Home
            </AniLink>
            <AniLink 
            className="nav-link" 
            hex="#FF4B00"
            paintDrip 
            to="/about">
              About
            </AniLink>
            <AniLink 
            className="nav-link" 
            cover
            bg="#000" 
            entryoffset={80}
            direction="down"
            to="/services">
              Services
            </AniLink>

            <NavDropdown alignRight title="Projects" id="basic-nav-dropdown">
              <AniLink 
                className="dropdown-item" 
                cover
                bg="#fff" 
                entryoffset={80}
                direction="right"
                to="/projects">
                  Gallery
                </AniLink>
                <NavDropdown.Divider />

              {data.allMarkdownRemark.edges.map(({ node }) => (
                <div key={node.id}>
                <AniLink 
                className="dropdown-item" 
                cover
                bg="#fff" 
                entryoffset={80}
                direction="right"
                to={node.fields.slug}>
                  {node.frontmatter.title}
                </AniLink>
                <NavDropdown.Divider />
                </div>
              ))}
            </NavDropdown>

            <AniLink 
            className="nav-link" 
            cover
            bg="#fff" 
            entryoffset={80}
            direction="right"
            to="/projects">
              Contact
            </AniLink>
          </Nav>
        </Navbar.Collapse>
       
      </Navbar>
      )}
      
    />
  )
}

